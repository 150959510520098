/* eslint-disable */
const firebaseConfig = {
  apiKey: "AIzaSyBIrH052Z9aQvcwxutVBh-zgtAXNLqB6So",
  authDomain: "veikul-c781a.firebaseapp.com",
  projectId: "veikul-c781a",
  storageBucket: "veikul-c781a.appspot.com",
  messagingSenderId: "719636155479",
  appId: "1:719636155479:web:7e7dd2446eba940ef42712",
};

const rrfConfig = {
  userProfile: "Users",
  useFirestoreForProfile: true,
};

export { firebaseConfig, rrfConfig };
