/* eslint-disable */
const initState = {
  students: [
    {
      dashError: null,
    },
  ],
};
const dashReducer = (state = initState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
export default dashReducer;
