import React, { useState, useEffect } from "react";

import USA from "./USA.jpg";
import MX from "./MX.jpg";

const App = () => {
  const [width, setWidth] = useState(window.innerWidth);
  console.log(width);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  if (width > 1000) {
    return (
      <div style={{ display: "flex", width: "100%" }}>
        <a
          href="https://us.ameshop.com"
          style={{ width: "50%", height: "100%" }}
        >
          <img src={USA} style={{ width: "100%", height: "100%" }} />
        </a>
        <a
          href="https://mx.ameshop.com"
          style={{ width: "50%", height: "100%" }}
        >
          <img src={MX} style={{ width: "100%", height: "100%" }} />
        </a>
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <a
          href="https://us.ameshop.com"
          style={{ width: "100%", height: "50%" }}
        >
          <img src={USA} style={{ width: "100%", height: "100%" }} />
        </a>
        <a
          href="https://mx.ameshop.com"
          style={{ width: "100%", height: "50%" }}
        >
          <img src={MX} style={{ width: "100%", height: "100%" }} />
        </a>
      </div>
    );
  }
};

export default App;
